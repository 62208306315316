<template>
  <VMenu
    :bottom="true"
    :open-on-hover="true"
    :nudge-bottom="32"
    :open-delay="700"
    :close-delay="300"
    :min-width="400"
    :max-width="500"
    :close-on-click="false"
    :close-on-content-click="false"
    :disabled="!isTeamMember"
  >
    <template v-slot:activator="{ on }">
      <div class="skill" :class="classes" @click="onSkillPress" v-on="on">
        <div :class="$('body')">
          <div v-if="name" :class="$('name')">
            {{ name }}
          </div>
          <div v-if="!!favorite" :class="$('favorite')">
            <span :class="$('favorite-icon')"> &#11088; </span>
          </div>
        </div>

        <div
          v-if="isTeamMember && (linkedInEndorsements || corporateRatings.length)"
          v-tooltip="endorsementTooltip"
          :class="$('endorsements')"
        >
          <span :class="$('icon')">
            <Icon :theme="Icon.Theme.POSITIVE" :size="Icon.Size.SMALL" name="certification" />
          </span>
        </div>

        <slot name="prefix" />

        <div v-if="humanizedDuration !== null && humanizedDuration > 0" :class="$('duration')">
          {{ humanizedDuration }}
        </div>

        <Txt
          v-if="missionDuration"
          :class="$('duration')"
          :theme="Txt.Theme.GREY"
          :value="formatMissionSkillDuration(missionDuration)"
        />

        <slot name="suffix" />

        <button
          v-if="action"
          type="button"
          :class="$('action')"
          :disabled="disabled"
          @click="onActionPress"
          @mousedown.stop
          @mouseup.stop
        >
          <i v-if="actionIcon" :class="[$('action-icon'), actionIcon]" />
        </button>
      </div>
    </template>

    <SkillInfoCard :id="id" />
  </VMenu>
</template>

<script>
  import { mapGetters } from 'vuex'

  import I18nMixin from '@/mixins/I18nMixin'

  import Icon from '@/core/graphics/Icon/Icon'
  import SkillInfoCard from './SkillInfoCard'
  import Txt from '@/core/text/Txt/Txt'

  import {
    formatMonthDuration,
    formatMissionSkillDuration,
    formatHardSkillReview,
  } from '@/utils/format'

  const Action = {
    ADD: 'add',
    DELETE: 'delete',
  }

  const Size = {
    MEDIUM: 'medium',
    SMALL: 'small',
    BIG: 'big',
  }

  const Theme = {
    PRIMARY: 'primary',
    PRIMARY_ALT: 'primary-alt',
    SECONDARY: 'secondary',
    SECONDARY_ALT: 'secondary-alt',
    POSITIVE: 'positive',
    POSITIVE_ALT: 'positive-alt',
    NEGATIVE: 'negative',
    NEGATIVE_ALT: 'negative-alt',
    BLUE: 'blue',
    BLUE_DARK: 'blue-dark',
    GREY: 'grey',
    GREY_ALT: 'grey-alt',
    RED: 'red',
    RED_ALT: 'red-alt',
    RED_LIGHT: 'red-light',
    PLACEHOLDER_GREY: 'placeholder-grey',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
  Icon: Icon.__enums,
  SkillInfoCard: SkillInfoCard.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Action,Size,Theme,
    name: 'Skill',
    __enums: {
      Action,
      Size,
      Theme,
    },
    components: {
      Txt,
      Icon,
      SkillInfoCard,
    },
    mixins: [I18nMixin],
    props: {
      id: {
        type: Number,
        default: null,
      },
      /**
       * Name of the skill
       */
      name: {
        type: String,
        required: true,
      },
      /**
       * Amount of month the skill has been "trained"
       */
      duration: {
        type: Number,
        default: null,
      },
      /**
       * Amount of years required for a mission
       */
      missionDuration: {
        type: Number,
        default: null,
      },
      /**
       * Index of the skill as favorite skill (for a freelancer for example)
       */
      favorite: {
        type: Number,
        default: null,
      },
      /**
       * Action which must be performed when clicking the action button
       * Can be: "add" or "delete"
       */
      action: {
        type: String,
        default: null,
      },
      /**
       * Size of the skill (impacting height and font-size)
       * Can be: "small", "medium", "big"
       */
      size: {
        type: String,
        default: 'medium',
      },
      /**
       * Disable the skill and its action
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Fix the width of the duration
       */
      fixedWidth: {
        type: Boolean,
        default: false,
      },
      /**
       * Color theme applied to the skill
       */
      theme: {
        type: String,
        default: Theme.BLUE,
      },
      /**
       * Endorsements received on LinkedIn for this skill
       */
      linkedInEndorsements: {
        type: Number,
        default: null,
      },
      /**
       * Endorsements received on comet platform for this skill
       */
      corporateRatings: {
        type: Array,
        default: () => [],
      },
      /**
       * Is the skill full width or not
       */
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['isTeamMember']),
      /**
       * CSS classes applied to the skill container
       * @type {Object.<Boolean>}
       */
      classes() {
        const { theme, size, disabled, fullSkillClickable, fixedWidth, fullWidth } = this

        return {
          [`skill--${theme}`]: !!theme,
          [`skill--${size}`]: !!size,
          'skill--disabled': disabled,
          'skill--clickable': fullSkillClickable,
          'skill--fixed-width': fixedWidth,
          'skill--full-width': fullWidth,
        }
      },
      /**
       * String to display in the skill representing the duration
       * @type {String}
       */
      humanizedDuration() {
        const { duration } = this

        if (duration === null || duration === 0) {
          return duration
        }

        return formatMonthDuration(duration * 12, true)
      },
      /**
       * Icon to display in the action button
       * @type {String}
       */
      actionIcon() {
        const { action } = this

        switch (action) {
          case 'add':
            return 'icon-plus-circle'
          case 'delete':
            return 'icon-cross-circle'
          default:
            return null
        }
      },
      /**
       * Is 'true' if all the skill container must be clickable
       * @type {Boolean}
       */
      fullSkillClickable() {
        return this.action === 'add'
      },
      /**
       * Displays the endorsements the freelancer has received, on LinkedIn or the comet platform
       * @returns {String}
       */
      endorsementTooltip() {
        const { linkedInEndorsements, corporateRatings, __ } = this

        const endorsements = []
        if (linkedInEndorsements) {
          endorsements.push(__('cp:core:skill:linkedin-endorsements', { linkedInEndorsements }))
        }
        if (corporateRatings.length) {
          const corporateRatingsList = corporateRatings
            .map(corporateRating => formatHardSkillReview(corporateRating.rating))
            .join(' / ')
          endorsements.push(__('cp:core:skill:corporate-ratings', { corporateRatingsList }))
        }

        return endorsements.join('\n')
      },
    },
    methods: {
      formatMissionSkillDuration,
      /**
       * Fired when the skill container is pressed
       * @returns {void}
       */
      onSkillPress($event) {
        const { action, fullSkillClickable } = this

        if (fullSkillClickable) {
          $event.preventDefault()
          this.$emit('action', action)
        }
      },
      /**
       * Fired when the action button is pressed, whatever is the action
       * @returns {void}
       */
      onActionPress($event) {
        const { action, fullSkillClickable } = this

        if (!fullSkillClickable) {
          $event.preventDefault()
          this.$emit('action', action)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .skill {
    height: 32px
    max-width: 100%
    display: flex
    align-items: center
    margin: 0 4px 8px 0
    padding: 0
    box-sizing: border-box
    border-radius: $radius-extra-large
    font-size: 15px
    line-height: 32px
    color: var(--color-font)
    background-color: var(--color-skill-secondary)

    &-endorsements {
      .icon {
        vertical-align: middle;
      }
    }

    &-favorite {
      position: relative
      display: flex
      align-items: center
      margin-left: 12px

      &:first-child {
        margin: 0 12px
      }

      &-icon {
        width: 1em
        font-size: 1.7em
        line-height: inherit
      }
    }

    &-body {
      ellipsis()
      display: flex
      flex-direction: row
      flex: 1
      margin-right: 12px

      &:first-child {
        margin-left: 16px
      }

      &:last-child {
        margin-right: 16px
      }
    }

    &-name,
    &-duration {
      white-space: nowrap
    }

    &-name {
      ellipsis()
    }

    &-duration {
      height: calc(100% - 8px)
      display: flex
      align-items: center
      margin: 4px
      padding: 0 12px
      font-size: .9em
      background-color: #fff
      border-radius: 12px
      box-sizing: content-box

      :not(:last-child) {
        margin-right: 8px
      }
    }

    &-action {
      border: none
      width: 1.6em
      height: calc(100% - 8px)
      display: block
      padding: 0
      margin-right: 8px
      text-transform: none
      background-color: transparent
      color: inherit

      &-icon {
        display: block
      }
    }

    &:not(&--disabled) &-action:hover {
      transform: scale(.95)
    }

    &-action-icon {
      font-size: 1.5em
      color: inherit
    }

    &&--full-width {
      flex: 1
    }

    &&--fixed-width {
      .skill-duration {
        width: 45px
        justify-content: center
      }
    }

    &&--small&&--fixed-width {
      .skill-duration {
        width: 30px
      }
    }

    &&--medium&&--fixed-width {
      .skill-duration {
        width: 40px
      }
    }

    &&--big&&--fixed-width {
      .skill-duration {
        width: 55px
      }
    }

    &&--small {
      height: 24px
      font-size: 14px
      line-height: 24px
      border-radius: 12px

      .skill-duration {
        font-size: .8em
        border-radius: $radius-large
      }
    }

    &&--medium {
      height: 32px
      font-size: 15px
      line-height: 32px
      border-radius: 16px

      .skill-duration {
        border-radius: 12px
      }
    }

    &&--big {
      height: 40px
      font-size: 16px
      line-height: 40px
      border-radius: 20px

      .skill-duration {
        border-radius: $radius-extra-large
      }
    }

    &&--primary,
    &&--blue-dark {
      background-color: var(--color-skill-primary)
      color: var(--color-font-contrast)

      .skill-duration {
        color: var(--color-skill-primary)
      }
    }

    &&--primary-alt {
      background-color: var(--color-background)
      color: var(--color-skill-primary)
      border: 1px solid var(--color-skill-primary)
    }

    &&--secondary,
    &&--blue {
      background-color: var(--color-skill)
      color: var(--color-font-contrast)

      .skill-duration {
        color: var(--color-skill)
      }
    }

    &&--secondary-alt {
      background-color: var(--color-background)
      color: var(--color-skill)
      border: 1px solid var(--color-skill)
    }

    &&--negative,
    &&--red {
      background-color: var(--color-negative)
      color: var(--color-font-contrast)

      .skill-duration {
        color: var(--color-negative)
      }
    }

    &&--negative-alt,
    &&--red-alt {
      background-color: var(--color-background)
      color: var(--color-negative)
      border: 1px solid var(--color-negative)

      .skill-duration {
        color: var(--color-negative)
      }
    }

    &&--red-light {
      background-color: var(--color-negative-clear)
      color: var(--color-font-contrast)

      .skill-duration {
        color: var(--color-negative-clear)
      }
    }

    &&--positive,
    &&--green {
      background-color: var(--color-positive)
      color: var(--color-font-contrast)

      .skill-duration {
        color: var(--color-positive)
      }
    }

    &&--positive-alt,
    &&--green-alt {
      background-color: var(--color-background)
      color: var(--color-positive)
      border: 1px solid var(--color-positive)

      .skill-duration {
        color: var(--color-positive)
      }
    }

    &--grey-alt {
      background-color: var(--color-background)
      color: var(--color-grey-60)
      border: 1px solid var(--color-grey-50)
    }

    &--black {
      background-color: transparent
      border: 1px solid var(--color-grey-30)
      color: var(--color-font)
    }

    &&--placeholder-grey {
      background-color: var(--color-skill-placeholder-fill)
      border: 1px dashed var(--color-skill-placeholder-border)
      color: var(--color-grey-50)
      // cursor: pointer
    }

    &&--clickable {
      cursor: pointer
    }

    &&--disabled {
      opacity: .5
    }
  }
</style>
